import React from "react";
import classes from "../styles/services.module.css";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const title = "Services | Avenue Barber";
const description = "Men's/boy's haircuts | Senior (65+) cut | Buzzcut.";

export default function Services({ data, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/services" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          <div>
            <div className={classes.flexRow}>
              <h6>Men's Senior Cut 65+/Boys under 12</h6>
              <p>30 mins</p>
              <p>
                <strong>C$27</strong>
              </p>
            </div>
            <div className={classes.flexRow}>
              <h6>Men's/Boy's Haircut</h6>
              <p>30 mins</p>
              <p>
                <strong>C$30</strong>
              </p>
            </div>
            <div className={classes.flexRow}>
              <h6>Buzzcut</h6>
              <p>30 mins</p>
              <p>
                <strong>C$20</strong>
              </p>
            </div>
            <div className={classes.flexRow} style={{ marginBottom: 0 }}>
              <h6>Woman's Cut</h6>
              <p>30 mins</p>
              <p>
                <strong>C$40</strong>
              </p>
            </div>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
